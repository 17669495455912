import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

export default function MetaTitle ({ children }) {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => {
        const siteName = data.site.siteMetadata.title
        let title = siteName
        let socialTitle = siteName

        if (children) {
          title = `${children} | ${title}`
          socialTitle = `${children}` // og:title should not contain any branding: https://developers.facebook.com/docs/sharing/webmasters#basic
        }

        return (
          <Helmet>
            <title>{title}</title>
            <meta property='og:site_name' content={siteName} />
            <meta property='og:title' content={socialTitle} />
          </Helmet>
        )
      }}
    />
  )
}
