import $ from '@gizt/selector'
import shuffle from 'lodash.shuffle'
import React, { Component } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import Gallery from 'react-photo-gallery'

import Layout from '../../components/layout'
import PageTitle from '../../components/page-title'
import SubNavigation from '../../components/sub-navigation'
import Photo from '../../components/gallery/photo'
import MetaTitle from '../../components/meta-title'
import MetaDescription from '../../components/meta-description'

const cachedPhotos = {}

export default class PortfolioPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isModalOpen: false,
      imageIndex: null
    }

    this.openPhotoModal = this.openPhotoModal.bind(this)
    this.closePhotoModal = this.closePhotoModal.bind(this)
    this.goToPrevious = this.goToPrevious.bind(this)
    this.goToNext = this.goToNext.bind(this)

    this.cachedPhotos = cachedPhotos
  }

  render () {
    const {
      portfolioNavigationItems = [],
      title,
      metaDescription,
      slug: portfolioName,
      photos: photoData
    } = this.props.pageContext

    const photos = this.getPhotos(portfolioName, photoData)

    return (
      <Layout>
        <MetaTitle>{`Portfolio - ${title}`}</MetaTitle>
        <MetaDescription>{`${metaDescription}`}</MetaDescription>
        <div className='container-wide py-16 xl:py-40'>
          <PageTitle className='hidden'>Portfolio {title}</PageTitle>
          <SubNavigation
            className='md:hidden mb-32'
            menuItems={portfolioNavigationItems}
          />

          {photos.length > 0 && (
            <div className='-mx-4'>
              <Gallery
                photos={getGalleryPhotos(photos)}
                ImageComponent={Photo}
                onClick={this.openPhotoModal}
                margin={4}
              />
            </div>
          )}

          <ModalGateway>
            {this.state.isModalOpen ? (
              <Modal onClose={this.closePhotoModal}>
                <Carousel
                  views={getFullScreenPhotos(photos)}
                  currentIndex={this.state.imageIndex}
                  components={{
                    FooterCount: ({ currentIndex, views }) => {
                      const activeView = currentIndex + 1
                      const totalViews = views.length

                      if (!activeView || !totalViews) return null

                      return (
                        <span>
                          {activeView} / {totalViews}
                        </span>
                      )
                    }
                  }}
                  formatters={{
                    getAltText: CommonProps => '',
                    getNextTitle: CommonProps => 'Volgende foto',
                    getPrevTitle: CommonProps => 'Vorige foto',
                    getCloseLabel: CommonProps => 'Sluiten',
                    getFullscreenLabel: ({ isFullscreen }) =>
                      isFullscreen
                        ? 'Volledig scherm sluiten'
                        : 'Volledig scherm'
                  }}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </Layout>
    )
  }

  getPhotos (portfolioName, photoQueryData) {
    const photos = $('[].localFile', photoQueryData)

    if (
      !this.cachedPhotos[portfolioName] ||
      this.cachedPhotos[portfolioName].length !== photos.length
    ) {
      this.cachedPhotos[portfolioName] = shuffle(photos)
    }

    return this.cachedPhotos[portfolioName]
  }

  openPhotoModal (event, { index }) {
    this.setState({
      isModalOpen: true,
      imageIndex: index
    })
  }

  closePhotoModal () {
    this.setState({
      isModalOpen: false,
      imageIndex: null
    })
  }

  goToPrevious () {
    this.setState({
      imageIndex: this.state.imageIndex - 1
    })
  }

  goToNext () {
    this.setState({
      imageIndex: this.state.imageIndex + 1
    })
  }
}

function getGalleryPhotos (photos) {
  return photos.map(({ id: key, childImageSharp: { fixed } }) => {
    const { src, width, height } = fixed

    return {
      key,
      src,
      width,
      height,
      fixed
    }
  })
}

function getFullScreenPhotos (photos) {
  return photos.map(({ publicURL }) => {
    return {
      src: publicURL
    }
  })
}
