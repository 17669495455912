import React from 'react'
import Img from 'gatsby-image'
import { css } from 'emotion'
import cx from 'classnames'

export default function Photo ({
  photo,
  index,
  onClick,
  margin,
  direction,
  top,
  left
}) {
  const { fixed, key, width, height } = photo

  const sizeStyles = css`
    width: ${width}px !important;
    height: ${height}px !important;
  `

  let positionStyles = null
  if (direction === 'column') {
    positionStyles = cx(
      css`
        top: ${top}px;
        left: ${left}px;
      `,
      'absolute'
    )
  }

  return (
    <div
      className={cx(
        'cursor-pointer',
        sizeStyles,
        positionStyles,
        css`
          margin: ${margin}px;
        `
      )}
      onClick={onClick ? handleClick : null}
    >
      <Img
        fixed={fixed}
        className={sizeStyles}
        key={key}
        style={{
          display: 'inherit' // Disables the inline `display: inline-block;` styles
        }}
      />
    </div>
  )

  function handleClick (event) {
    onClick(event, { photo, index })
  }
}
