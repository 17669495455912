import React from 'react'
import Helmet from 'react-helmet'

import Menu from '../menu'

import '../../styles/tailwind.css'
import MetaTitle from '../meta-title'

import socialPreviewImage from '../../assets/images/social-preview.jpg'

function Layout ({ children }) {
  return (
    <>
      <MetaTitle />
      <Helmet>
        <meta name='language' content='nl' />
        {/* <meta property="og:url" content="http://www.nytimes.com/2015/02/19/arts/international/when-great-minds-dont-think-alike.html" /> */}
        <meta property='og:type' content='website' />
        <meta property='og:locale' content='nl' />
        <meta property='og:image' content={socialPreviewImage} />
        <meta property='og:image:type' content='image/jpeg' />
        <meta property='og:image:width' content='1500' />
        <meta property='og:image:height' content='1000' />
        <script
          async
          defer
          data-domain='laurenceverheijen.be'
          src='https://analytics.laurenceverheijen.be/js/plausible.js'
        />
      </Helmet>
      <section className='flex flex-col-reverse h-full'>
        <Menu />
        <main className='flex-grow h-full overflow-y-auto scrolling-touch'>
          {children}
        </main>
      </section>
    </>
  )
}

export default Layout
