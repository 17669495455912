import React from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'

export default function Logo ({ className }) {
  return (
    <Link to='/' className={cx('no-underline text-color-inherit', className)}>
      <figure className='m-0 text-18 font-semibold uppercase'>
        <span className='tracking-wide'>L</span>aurence
      </figure>
    </Link>
  )
}
