import { css } from 'emotion'

const MENU_SEPARATOR_HEIGHT = 14 // TODO: Should probably be equal to the font size

export const list = css`
  li + li {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: calc(50% - ${MENU_SEPARATOR_HEIGHT / 2}px);
      width: 1px;
      height: ${MENU_SEPARATOR_HEIGHT}px;
      background-color: #a5a5a5;
    }
  }
`

export const linkActive = css`
  color: #000000;
`
