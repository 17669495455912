import React from 'react'
import { Link } from 'gatsby'
import Logo from '../../logo'
import { NavigationBarLink, NavigationBarLinkActive } from './styles'
import { getMenuItems } from '../../../utils/navigation'
import cx from 'classnames'

export default function NavigationBar ({ portfolioPages }) {
  const flexClasses = 'flex-shrink-0'

  const isActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent
      ? { className: cx(NavigationBarLink, NavigationBarLinkActive) }
      : null
  }

  return (
    <header
      className={cx(
        flexClasses,
        'hidden md:block w-full bg-white py-24 border-0 border-t border-solid border-gray'
      )}
    >
      <div className='container-wide flex'>
        <Logo className='mr-32' />
        <nav className='flex-grow flex justify-between'>
          <ul className='flex -mx-16 my-0'>
            {getMenuItems(portfolioPages).map(({ id, url, title }) => (
              <li className='px-16' key={id}>
                <Link
                  className={NavigationBarLink}
                  activeClassName={NavigationBarLinkActive}
                  to={url}
                >
                  {title}
                </Link>
              </li>
            ))}
          </ul>

          <ul className='flex -mx-16 my-0'>
            <li className='px-16'>
              <Link
                to='/info'
                className={NavigationBarLink}
                getProps={isActive}
              >
                Info
              </Link>
            </li>
            <li className='px-16'>
              <Link
                to='/tarieven'
                className={NavigationBarLink}
                getProps={isActive}
              >
                Tarieven
              </Link>
            </li>
            <li className='px-16'>
              <Link
                to='/contact'
                className={NavigationBarLink}
                getProps={isActive}
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}
