import React from 'react'
import { Link } from 'gatsby'
import { Match } from '@reach/router'

import TabIcon from './tab-icon'

import homeIcon from '../../../assets/images/tab-navigation/home.svg'
import homeIconActive from '../../../assets/images/tab-navigation/home--active.svg'
import portfolioIcon from '../../../assets/images/tab-navigation/portfolio.svg'
import portfolioIconActive from '../../../assets/images/tab-navigation/portfolio--active.svg'
import infoIcon from '../../../assets/images/tab-navigation/info.svg'
import infoIconActive from '../../../assets/images/tab-navigation/info--active.svg'
import tarrifsIcon from '../../../assets/images/tab-navigation/tarrifs.svg'
import tarrifsIconActive from '../../../assets/images/tab-navigation/tarrifs--active.svg'
import contactIcon from '../../../assets/images/tab-navigation/contact.svg'
import contactIconActive from '../../../assets/images/tab-navigation/contact--active.svg'

const tabClasses =
  'flex flex-col items-center justify-center no-underline text-inherit focus:text-inherit'

export default function TabNavigation ({ portfolioPages }) {
  return (
    <header className='md:hidden flex-shrink-0 w-full bg-white border-0 border-t border-solid border-gray px-0 py-8'>
      <nav>
        <ul className='m-0 flex justify-around'>
          <li>
            <Link to='/' className={tabClasses}>
              <Match path='/'>
                {({ match: isActive }) => {
                  return (
                    <TabIcon
                      isTabActive={isActive}
                      iconDefault={homeIcon}
                      iconActive={homeIconActive}
                    >
                      Home
                    </TabIcon>
                  )
                }}
              </Match>
            </Link>
          </li>
          <li>
            <Link to='/portfolio/paarden' className={tabClasses}>
              <Match path='/portfolio/:portfolio-type'>
                {({ match: isActive }) => {
                  return (
                    <TabIcon
                      isTabActive={isActive}
                      iconDefault={portfolioIcon}
                      iconActive={portfolioIconActive}
                    >
                      Portfolio
                    </TabIcon>
                  )
                }}
              </Match>
            </Link>
          </li>
          <li>
            <Link to='/info' className={tabClasses}>
              <Match path='/info/:info-type'>
                {({ match: isActive }) => {
                  return (
                    <TabIcon
                      isTabActive={isActive}
                      iconDefault={infoIcon}
                      iconActive={infoIconActive}
                    >
                      Info
                    </TabIcon>
                  )
                }}
              </Match>
            </Link>
          </li>
          <li>
            <Link to='/tarieven' className={tabClasses}>
              <Match path='/tarieven/:tarrif-type'>
                {({ match: isActive }) => {
                  return (
                    <TabIcon
                      isTabActive={isActive}
                      iconDefault={tarrifsIcon}
                      iconActive={tarrifsIconActive}
                    >
                      Tarieven
                    </TabIcon>
                  )
                }}
              </Match>
            </Link>
          </li>
          <li>
            <Link to='/contact' className={tabClasses}>
              <Match path='/contact'>
                {({ match: isActive }) => {
                  return (
                    <TabIcon
                      isTabActive={isActive}
                      iconDefault={contactIcon}
                      iconActive={contactIconActive}
                    >
                      Contact
                    </TabIcon>
                  )
                }}
              </Match>
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}
