import React from 'react'
import cx from 'classnames'

export default function TabIcon ({
  iconDefault,
  iconActive,
  isTabActive,
  children
}) {
  const icon = isTabActive ? iconActive : iconDefault

  return (
    <>
      <img className='w-24 h-24' src={icon} alt='' />
      <p
        className={cx(
          'm-0 pt-4 text-12 leading-none',
          isTabActive ? 'text-black' : 'text-gray-dark'
        )}
      >
        {children}
      </p>
    </>
  )
}
