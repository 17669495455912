import React from 'react'
import Helmet from 'react-helmet'

export default function MetaDescription ({ children: description }) {
  return (
    <Helmet>
      <meta name='description' content={description} />
      <meta property='og:description' content={description} />
    </Helmet>
  )
}
