import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import NavigationBar from './navigation-bar'
import TabNavigation from './tab-navigation'

export default function Menu () {
  // TODO: Find a way to deduplicate the query, it's confusing.. Find a way to inject data in all pages
  // Keep the query in sync the page-query.js
  return (
    <StaticQuery
      query={graphql`
        query PageQuery {
          portfolioPages: allContentfulPortfolio(sort: { fields: order }) {
            edges {
              portfolioData: node {
                id
                title
                slug
                order
                metaDescription: childContentfulPortfolioMetaDescriptionTextNode {
                  metaDescription
                }
                photos {
                  localFile {
                    id
                    publicURL
                    childImageSharp {
                      fixed(height: 550) {
                        base64
                        width
                        height
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        originalName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({ portfolioPages }) => {
        return (
          <>
            <TabNavigation portfolioPages={portfolioPages} />
            <NavigationBar portfolioPages={portfolioPages} />
          </>
        )
      }}
    />
  )
}
