exports.getMenuItems = function (queryPages) {
  return queryPages.edges.map(({ portfolioData }) => {
    return {
      id: portfolioData.id,
      url: generatePortfolioUrl(portfolioData.slug),
      title: portfolioData.title
    }
  })
}

function generatePortfolioUrl (slug) {
  return `/portfolio/${slug}`
}

exports.generatePortfolioUrl = generatePortfolioUrl
