import React from 'react'
import { Link } from 'gatsby'
import { list, linkActive } from './styles'
import cx from 'classnames'

const isActive = ({ isPartiallyCurrent }) => {
  return {
    className: cx(
      'font-semibold no-underline uppercase text-gray-dark hover:text-black focus:text-black trans trans-color',
      {
        [linkActive]: isPartiallyCurrent
      }
    )
  }
}

export default function SubNavigation ({ className, menuItems }) {
  if (menuItems.length === 0) {
    return null
  }

  return (
    <ul className={cx(className, 'flex flex-wrap -mx-8', list)}>
      {menuItems.map(({ id, title, url }) => (
        <li key={id} className='py-0 px-8 relative'>
          <Link to={url} getProps={isActive}>
            {title}
          </Link>
        </li>
      ))}
    </ul>
  )
}
