import React from 'react'
import classNames from 'classnames'

export default function PageTitle ({ className, children, ...props }) {
  return (
    <h1
      className={classNames('mt-0 text-20 font-semibold uppercase', className)}
    >
      {children}
    </h1>
  )
}
