import { css } from 'emotion'

const LINK_DECORATION_WIDTH = 50

export const NavigationBarLink = css`
  text-decoration: none;
  position: relative;
  color: #000000;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -8px;
    left: calc(50% - ${LINK_DECORATION_WIDTH / 2}%);
    width: ${LINK_DECORATION_WIDTH}%;
    height: 1px;
    transform: scaleX(0);
    transition: transform 200ms ease-in-out;
    background-color: currentColor;
  }

  &:hover,
  &:focus {
    &::after {
      transform: scaleX(1);
    }
  }
`

export const NavigationBarLinkActive = css`
  font-weight: 600;

  &::after {
    transform: scaleX(1);
  }
`
